<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='cts m-auto'>
        <div class='flex mt-8 text-gray-700'>
          <router-link class='flex'
            :to='{ name: "settings" }'>
            <i class='icon'>arrow_back</i>
          </router-link>
          <div class='ml-16 text-lg font-bold'>{{ $t('pages.contact-us.de3eca7e') }}</div>
        </div>
        <div class='mt-24'>
          <input-element class='shadow-sm'
            type='text'
            v-model='inputs.name'
            :placeholder='$t("common.name")'/>
          <input-element class='mt-16 shadow-sm'
            type='email'
            v-model='inputs.email'
            :placeholder='$t("common.email")'/>
          <input-element class='mt-16 shadow-sm'
            type='text'
            v-model='inputs.subject'
            :placeholder='$t("common.subject")'/>
          <textarea-element class='tvc mt-16 shadow-sm'
            v-model='inputs.message'
            :placeholder='$t("common.message")'/>
        </div>
        <button-element class='is-block p-16 mt-24'
          :disabled='loading'
          @click.native='sendContact'>
          <spinner-element class='mr-16 -ml-16 text-gray-500'
            v-if='loading'/>
          <div>{{ $t('common.send-email') }}</div>
        </button-element>
      </div>
    </div>
    <snackbar-element
      ref='successSnackbar'
      :duration='3000'>
      <div class='flex p-16 text-green'>
        <i class='icon mr-16'>check_circle</i>
        <div v-if='success === "contact-sent"'>{{ $t('pages.contact-us.ec69a0b8') }}</div>
      </div>
    </snackbar-element>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "invalid-email"'>{{ $t('pages.contact-us.5997e2ae') }}</div>
        <div v-if='error === "unknown"'>{{ $t('pages.contact-us.cac2b4ac') }}</div>
      </div>
    </snackbar-element>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import TopNavigation from '@/components/top-navigation'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'top-navigation-component': TopNavigation
    },

    data: () => ({
      inputs: {
        email: '',
        subject: '',
        name: '',
        message: ''
      },
      success: '',
      error: '',
      loading: false
    }),

    methods: {
      validateInputs () {
        let { email, subject, name, message } = this.inputs
        if (!email || !subject || !name || !message) return
        return this.inputs
      },

      async sendContact () {
        let contact = this.validateInputs()

        if (!contact) return

        try {
          this.loading = true

          await this.$store.dispatch('contact/sendContact', { contact })

          this.success = 'contact-sent'
          this.$refs.successSnackbar.show()
        } catch (error) {
          if (error.response && error.response.data) {
            let code = error.response.data.code
            if (code === 'invalid-email') {
              this.error = 'invalid-email'
              this.$refs.errorSnackbar.show()
              return
            }
          }

          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style lang='scss' scoped>
  .cts {
    max-width: 360px;
  }

  .tvc::v-deep .tex {
    height: 160px;
  }
</style>
